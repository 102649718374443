import Page from 'components/Page';
import React from 'react';
import {
  Row,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Table,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownItem, 
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

  const TaskWidget = ({
    task,
    ...restProps
    }) => {
    return (
     
        <Row>
          <Col xl={12} lg={12} md={12}>
          
                {(() => {
                  //alert(JSON.stringify(this.props && this.props.TASK_TIMELINE_VIEW &&  this.props.TASK_TIMELINE_VIEW.task_log))
                  if(JSON.stringify(task)){
                    return(
                      <VerticalTimeline  layout={'1-column'} >
                        {task.task_log.map((row, index )=>(
                          <VerticalTimelineElement   className="vertical-timeline-element--work" style={{marginBottom:'0 !important'}}             contentStyle={{ background: '#E1E1E1', color: '#000000',paddingTop:2,paddingBottom:2, }}     contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)'   }}       date={Object.values(row.log_date)}     iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}>

                         
                  
                          {index==0?<div style={{fontSize:'13px'}}>
                            <small>Task: {Object.values(row.status_ID)}</small>
                            <div style={{fontSize:'16px'}} className="vertical-timeline-element-title" >{task.task_detail.subject}</div> 
                            <i>{task.task_detail.message}</i><br/>
                            <div style={{fontSize:'11px',color:'#878787'}}>
                              <b>Assigned To:</b> {task.task_assigned.map(row=>(
                              <div>{row.emp_name}</div>))}
                            </div>
                          </div>
                          :
                          <div style={{fontSize:'13px'}}>
                            <small>Task: {Object.values(row.status_ID)}</small>
                            <div style={{fontSize:'16px'}} className="vertical-timeline-element-title">{Object.values(row.message)}</div>  
                        
                            <div style={{fontSize:'11px',color:'#0000FF'}}>
                              <b>Assigned To:</b> { task.task_assigned.map(row=>(
                              <div>{row.emp_name}</div>))}
                            </div>
                          </div>}
                          {console.log(index)}
                            </VerticalTimelineElement>
                           ))}
                        </VerticalTimeline>
                    )
                  }
                })()}
            
           
          </Col>
        </Row>
    );
  }

  TaskWidget.propTypes = {
  }
  
  TaskWidget.defaultProps = {
    task:{
      task_detail: {
      ID: 0,
      parent_ID: 0,
      created_by: 1,
      created_by_name: "",
      priority_type: "",
      followup_type: "",
      date_of_created: "",
      assigned_date: "",
      subject: "",
      message: "",
      reply_date: "",
      last_update: ""
    },
    task_log: [
      {
        ID: "",
        task_ID: "",
        status_ID: "",
        log_date: "",
        message: "",
        status_date: ""
      }
    ],
    task_assigned: [
      {
        user_ID: "",
        emp_name: "",
        libal_order: ""
      }
    ],
    task_followup: [
      {
        user_ID: "",
        emp_name: "",
        libal_order: ""
      }
    ]

    }
 
  }

export default TaskWidget;