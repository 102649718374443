import React from 'react';
import PropTypes from 'utils/propTypes';
import { getColor } from 'utils/colors';
import { Media, Button } from 'reactstrap';
import Avatar from './Avatar';
import {
  MdBubbleChart,
  MdInsertChart,
  MdPersonPin,
  MdPieChart,
  MdRateReview,
  MdShare,
  MdShowChart,
  MdThumbUp,
  MdLanguage,
  MdLightbulbOutline,
  MdMailOutline,
  MdPlayCircleOutline,
  MdRadio,
  MdSnooze,
  MdThumbsUpDown,
  MdFace,
  MdNoteAdd,
  MdDoneAll,
  MdVisibility,
  MdOpenInNew,
} from 'react-icons/md';

const statusMap = {
  Created: 'primary',
  Processing: 'muted',
  Hold: 'muted',
  Rejected: 'muted',
  Completed: 'success',
};

const mdMap = {
  Created: 'MdNoteAdd',
  Processing: 'MdNoteAdd',
  Hold: 'MdNoteAdd',
  Rejected: 'MdNoteAdd',
  Completed: 'MdDoneAll',
};

const RecentTaskDashboard = ({
  avatar,
  taskID,
  name,
  date,
  subject,
  text,
  status,
  endDate,
  onClick,
  backcolor,
  onTaskClick,
  newMessage,
  ...restProps
}) => {
    const primaryColor = getColor('primary');
  return (
    <div class="m-0" {...restProps} style={{background:backcolor}} >
    heloo recent task
     {/* <Media className="m-0">
        <Media body >
          <Media   heading tag="h6" className="m-0" style={{fontSize:13}}>
           <b> {name.toUpperCase()}</b>
          </Media>
          <div><div className="text-primary" style={{fontSize:12}}>{subject}</div>
          <div className="text-secondary" style={{fontSize:11}}>{text}</div>
           <div className="text-secondary" style={{fontSize:11}}>Recent Reply: <b>{newMessage}</b></div>
          </div>
        </Media>
        <Media right className="align-self-center m-0">
       
          <Button
            color="link"
            size="sm"
            className={" text-"+statusMap[status]  }
            onClick={()=>onTaskClick(taskID+","+status)}
          >
            {(() => {
              if(status=="Completed"){
                return(
                (
                  <div style={{fontSize:12}}>{date} <MdOpenInNew size={25}  /></div>
                ))
              }
              else{
                return(
                  <div style={{fontSize:12}}>{date} <MdOpenInNew size={25}  /></div>
                )
              }
            })()}
          </Button>
        </Media>
      </Media>
      <Media>
      
      </Media>
       <hr></hr>
    
    */}
    </div>
  );
};

RecentTaskDashboard.propTypes = {
  avatar: PropTypes.string,
  taskID: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.date,
  subject:PropTypes.string,
  text: PropTypes.string,
  endDate:PropTypes.date,
  status: PropTypes.oneOf(['Created', 'Processing', 'Hold','Rejected','Completed']),
  onClick: PropTypes.func,
  backcolor:PropTypes.string,
  onTaskClick:PropTypes.fun,
  newMessage:PropTypes.string,
};

RecentTaskDashboard.defualtProps = {
  avatar: '',
  taskID: '',
  name: '',
  date: '',
  subject:'',
  text: '',
  endDate:'',
  status: 'pending',
  onClick: () => {},
  backcolor:'',
  onTaskClick:(e)=>{},
  newMessage:'',
};

export default RecentTaskDashboard;
