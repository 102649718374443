import React from 'react';
import PropTypes from 'utils/propTypes';

import { Card, CardText, CardTitle, Progress,Row,Col } from 'reactstrap';
import Typography from '../Typography';

const TaskWidget = ({
  title,
  subtitle,
  number,
  completed: { cvalue, clabel },
  pending: { pvalue, plabel },
  pendingApproval:{ avalue, alabel },
  color,
  progress: { value, label },
  subtitleColor,
  ...restProps
}) => {
  return (
    <Card body {...restProps}>
      <div className="d-flex justify-content-between">
        <CardText tag="div">
          <Typography className="mb-0">
            <strong>{title}</strong>
          </Typography>
          <Typography className=  {"mb-0  small "+subtitleColor}> <strong>{subtitle}</strong></Typography>
        </CardText>
        <CardTitle className={`text-${color}`}>{number}</CardTitle>
      </div>
     <CardText tag="div" className="d-flex justify-content-between">
        <Typography tag="span" className="text-left text-muted small">
          {clabel}
        </Typography>
        <Typography tag="span" className="text-right text-muted small">
          {cvalue}
        </Typography>
      </CardText>
      
   
      <CardText tag="div" className="d-flex justify-content-between">
        <Typography tag="span" className="text-left text-muted small">
          {plabel}
        </Typography>
        <Typography tag="span" className="text-right text-muted small">
          {pvalue}
        </Typography>
      </CardText>

      
      <CardText tag="div" className="d-flex justify-content-between">
        <Typography tag="span" className="text-left text-muted small">
          {alabel}
        </Typography>
        <Typography tag="span" className="text-right text-muted small">
          {avalue}
        </Typography>
      </CardText>

        {value>0?<Progress value={value} color={color}  style={{ height: '14px' }} >{value}%</Progress>:<small>No Task Completed</small>}
       
       
    </Card>
  );
};

TaskWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  number: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  completed:PropTypes.shape({
    cvalue: PropTypes.number,
    clabel: PropTypes.string,
  }),
  pending:PropTypes.shape({
    pvalue: PropTypes.number,
    plabel: PropTypes.string,
  }),

  pendingApproval:PropTypes.shape({
    avalue: PropTypes.number,
    alabel: PropTypes.string,
  }),

  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
  ]),
  progress: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  subtitleColor:PropTypes.string,
};

TaskWidget.defaultProps = {
  title: '',
  subtitle: '',
  number: 0,
  completed: { 
    cvalue:0, 
    clabel:"Completed" 
  },
  pending: { 
    pvalue:0, 
    plabel:"Pending" 
  },
  pendingApproval: { 
    avalue:0, 
    alabel:"Pending Approval" 
  },
  color: 'primary',
  progress: {
    value: 0,
    label: '',
  },
  subtitleColor:'',
};

export default TaskWidget;
