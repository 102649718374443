import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
     
      <Layout >
       <div style={{background: 'linear-gradient(to bottom, #627EDA, #262055)'}}>

        
        <Component {...props} />
         </div>
      </Layout>
     
    )}
  />
);

export default LayoutRoute;
