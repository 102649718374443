import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import queryString from 'query-string';
let username=""
let password=""
let type=""
class AuthPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewTask:{username:'', password:'',  },
      loading:false,
    }
  } 

  handleAuthState = authState => {
   // alert("ok1")
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
   //alert("ok1")
    this.props.history.push('/');
  };

  onSubmitNew = () => { this.props.history.push('/user-password-recovery'); };

  render() {
      let  parse = queryString.parse(this.props.location.search);
      console.log(parse)
      if (this.props.location.search=="" || this.props.location.search==undefined){ type="WEB" }
      else {
        username=parse.username
        password=parse.password
        type="APP"
        }
        console.log(username+password+type)

    return (
      <Row style={{height: '100vh',justifyContent: 'center',alignItems: 'center', margin:10}}>
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              username={username}
              password={password}
              type={type}
            />
            
            <br/><hr/>
              <Button color="link" onClick={()=>this.onSubmitNew()}>Forget Password </Button>
          </Card>
        </Col>
      </Row>
       
    );
  }

    componentDidMount() {
    
      
    


  /*  if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.user_ID!=0){
     
    }else{
        this.props.history.push({
        pathname: '/'
      })
    }*/
    
  }

  componentWillReceiveProps(nextProps) {
    console.log("aut pagenext props")
    console.log(nextProps)
    this.setState({loading:false},()=>{console.log("okkkkk")})
    if(nextProps.LOGIN_CHECK.login==true){
      this.props.history.push({pathname: '/front-page',state: { userID:nextProps.LOGIN_CHECK.user_ID }})
    }
    else if(nextProps.LOGIN_CHECK.login==false)  {
      if (nextProps.LOGIN_CHECK.msg=="Logout"){
        //alert(nextProps.LOGIN_CHECK.msg)
        } else{ 
          alert(nextProps.LOGIN_CHECK.msg)
        }
    }
  }


}
function mapStateToProps({ mainReducers}) {
 
  //console.log("First")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     LOGIN_CHECK: mainReducers.LOGIN_CHECK,
    
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthPage) ;

