import { persistCombineReducers } from 'redux-persist'
//import storage from '@react-native-community/async-storage';
import storage from 'redux-persist/lib/storage';
import mainReducers from './mainReducer'

const config = {
  key: 'root',
  storage ,
  whitelist: ["mainReducers"]
}

const reducers = persistCombineReducers(config, {
  mainReducers,
  
})

export default reducers
