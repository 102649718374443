import Page from 'components/Page';
import { IconWidget, NumberWidget,TaskWidget,TaskWidgetAssigned,TaskWidgetReceived} from 'components/Widget';
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { randomNum } from 'utils/demos';
import {
  MdBubbleChart,
  MdInsertChart,
  MdPersonPin,
  MdPieChart,
  MdRateReview,
  MdShare,
  MdShowChart,
  MdThumbUp,
  MdLanguage,
  MdLightbulbOutline,
  MdMailOutline,
  MdPlayCircleOutline,
  MdRadio,
  MdSnooze,
  MdThumbsUpDown,
} from 'react-icons/md';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardDeck,
  CardGroup,
  CardHeader,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  Label
} from 'reactstrap';
// Redux Import FIle Start
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
// Redux Import File Ends
import { getColor } from 'utils/colors';
import RecentTask from 'components/RecentTask';
import RecentTaskDashboard from 'components/RecentTaskDashboard';
const today = new Date();
const lastWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7,
);

class Dashboard extends React.Component {
   constructor (props) {
    super(props)
    this.state = {
      form:{schema:{},formData:{},uischema:{}},
      eventFieldID:"",
    
      loading:false,      
    }
  } 

  onSubmitNew = () => {
    //alert(JSON.stringify(this.props.LOGIN_CHECK))
    if(this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.level==2){
      this.props.history.push({pathname: '/dashboard',
      state: { userID:this.props.LOGIN_CHECK.user_ID }
      })
    }else if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.level==1){
      this.props.history.push({pathname: '/dashboard',
        state: { userID:this.props.LOGIN_CHECK.user_ID }
      })
    }
  };


  onSubmitList = () => {
    alert("List")
  }

  onSubmitKey = () => {
    alert("Key")
  }


  render() {
    const primaryColor = getColor('primary');
    const secondaryColor = getColor('secondary');
    let dashboard=new Array()
    if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.user_ID!=0){
       return (
      <Page className="DashboardPage"  title="" >
      <Row style={{height:'35vh' ,justifyContent: 'center', alignItems: 'center',}}>
        <Col xl={12} lg={12} md={12}></Col>
      </Row>
   
      <Row style={{justifyContent: 'center', alignItems: 'center',}}>
        <Col md={3} lg={3}>
        
        </Col>
         <Col md={3} lg={3}>
          <Card>
              <Button color="link" onClick={()=>this.onSubmitNew()}>Followup   </Button>
          </Card>
        </Col>
         <Col md={3} lg={3}>
          
        </Col>
      </Row>
   <Row style={{justifyContent: 'center', alignItems: 'center',}}> </Row>
      </Page>
    );
    }
    else{
      return(
        <div>
       
    </div>
      )  
    }   
  }

  componentWillMount(){}
  
  componentDidMount() {
 
    if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.user_ID!=0){
      //console.log("mount")
      let data={
        'userID':this.props.LOGIN_CHECK.user_ID,
      }
      this.props.viewDashboardDetail("viewDashboardDetail",data);
    }else{
        this.props.history.push({
      pathname: '/'
    })
    }
    
  }

  componentWillReceiveProps(nextProps) {
   if (nextProps.LOGIN_CHECK.login==false && nextProps.LOGIN_CHECK.user_ID==0){
        nextProps.history.push({
        pathname: '/'
        })

  }else{
      if (nextProps.TYPE=="VIEW_DASHBOARD"){
        
      }
        ////////else ends
    }

   // console.log(this.state.formDashboard.totalAssignTask)
  }

///Class Ends Here
}

function mapStateToProps({ mainReducers}) {
  //console.log("main reducer")
  //console.log(mainReducers)
  return {
     TYPE:mainReducers.TYPE,
     LOGIN_CHECK:mainReducers.LOGIN_CHECK,
   
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

