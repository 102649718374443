import { Content } from 'components/Layout';
import React from 'react';

const EmptyLayoutNew = ({ children, ...restProps }) => (
  <div {...restProps}>
   {children}
  </div>
);

export default EmptyLayoutNew;
