import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col ,InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';

import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'
import {
  MdVisibility,
  MdVisibilityOff
} from 'react-icons/md';
class UserPasswordReset extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        password:"",
        username:"",
        user_ID:""
      },
      loading:false,
      flag:0,
    }
  } 


  handleSubmit = event => {
    if (this.state.formNewInput.password===""){
      alert("Password is Empty")
    }
    else{
      //alert(JSON.stringify(this.state.formNewInput))
      this.props.userUpdatePassword("userUpdatePassword",this.state.formNewInput);
    }
  };

  formNewInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    console.log(this.state.formNewInput)
    console.log("value"+value)
  }
  onSubmit = () => {
   // alert("ok1")
    this.props.history.push('/');
  };
  onIconClick=()=>{
    if (this.state.flag==0){
      this.setState({flag:1},()=>{})
    }else{
      this.setState({flag:0},()=>{})
    }
    
  }

  onKeyPress=(e)=> {
    if (e.key === 'Enter') {
     this.handleSubmit()
    }
  }
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;
    let inputType=this.state.flag==0?"password":"text"
    let loginButton;
    if (this.state.flag==0) {
      loginButton = <MdVisibility className={'nav-item-icon'}  style={{padding: 0}}/>;
    } else {
      loginButton = <MdVisibilityOff className={'nav-item-icon'}  style={{padding: 0}} />;
    }
    return (
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'100vh', color:'white' }}>
      <div className="col-md-3"  >
    
      <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'>
        <Form onSubmit={this.handleSubmit}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 250, height: 20, cursor: 'pointer' }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
    
            <FormGroup>
            <Label for="password">Password</Label>
             <InputGroup size="sm">
              <Input
                bsSize="sm"
                type={inputType}
                name="password"
                id="password"
                placeholder="Enter Password"
                onChange={this.formNewInputChange}
                onKeyDown={this.onKeyPress}
              />
              <InputGroupAddon addonType="append" size="sm">
                <InputGroupText size="sm">
                  <i   onClick={()=>this.onIconClick()}>
                  {loginButton}
                    
                  </i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <hr />
            <Button
            size="sm"
            className="border-0"
            color="primary"
            block
            onClick={this.handleSubmit}>
            Save
          </Button>
          <Button   size="sm" className="border-0" color="link" onClick={()=>this.onSubmit()}> Sign In </Button>
      
          {children}
        </Form>
      </LoadingOverlay>
    
      </div>
      </div>
    );
  }
  componentDidMount() {
    //alert(JSON.stringify(this.props.location.state))
    this.state.formNewInput.username=this.props.location.state.username
    this.state.formNewInput.otp_sms=this.props.location.state.otp
    this.state.formNewInput.user_ID=this.props.location.state.user_ID
  }

  componentWillReceiveProps(nextProps) {
     this.setState({loading:false},()=>{console.log("okkkkk")})

    if (nextProps.TYPE=='USER_UPDATE_PASSWORD'){
       alert(JSON.stringify(nextProps.USER_UPDATE_PASSWORD.MSG))
      this.props.history.push({pathname: '/',state: { } })
    }

  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserPasswordReset.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserPasswordReset.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  console.log("main reducers")
  console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     USER_REGISTRATION: mainReducers.USER_REGISTRATION,
     USER_UPDATE_PASSWORD:mainReducers.USER_UPDATE_PASSWORD
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPasswordReset) ;

