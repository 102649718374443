import React from 'react';
import PropTypes from 'utils/propTypes';
import { Media } from 'reactstrap';
import Avatar from 'components/Avatar';
 // Redux Import FIle Start
 import { connect } from "react-redux";
 import { ActionCreators } from "actions";
 import { bindActionCreators } from "redux";
 import * as ActionTypes from "actions/ActionTypes";
 // Redux Import File Ends

const Notifications = ({ notificationsData }) => {
  return (
    notificationsData &&
    notificationsData.length &&
    notificationsData.map(({ id, avatar, message, date }) => (
      <Media key={id} className="pb-2">
        <Media left className="align-self-center pr-3">
          <Avatar tag={Media} object src={avatar} alt="Avatar" />
        </Media>

        <Media body middle className="align-self-center">
          Gagandeep Singh<br/>
          <strong>Task Created:{"  "} </strong><br/> {message}
        </Media>
        <Media right className="align-self-center">
          <small className="text-muted">{date}</small>
        </Media>
      </Media>
    ))
  );
};

Notifications.propTypes = {
  notificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.ID,
      avatar: PropTypes.string,
      message: PropTypes.node,
      date: PropTypes.date,
    })
  ),
};

Notifications.defaultProps = {
  notificationsData: [],
};

function mapStateToProps({ mainReducers}) {
  console.log("First")
  console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
    
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications) ;
