import Page1 from 'components/Page';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownItem, 
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
 
} from 'reactstrap';
import Select from 'react-select';
 import Form from "react-jsonschema-form";
 import LayoutField from 'react-jsonschema-form-layout-2'
 import LoadingOverlay from 'react-loading-overlay'
 // Redux Import FIle Start
 import { connect } from "react-redux";
 import { ActionCreators } from "actions";
 import { bindActionCreators } from "redux";
 import * as ActionTypes from "actions/ActionTypes";
 import MyTaskTimeline from 'components/MyWidget/TaskTimeline';
 // Redux Import File Ends

 import { PDFViewer,Document,Page,Text,Image } from '@react-pdf/renderer';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import {ReactDOMServer} from 'react-dom/server';
import Moment from 'moment';
const log = (type) => console.log.bind(console, type);
const fields = {
  layout: LayoutField
}
let m=0

class TestPage  extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      task:[
        {
          total:0,
          today:0,
          task_header:{
            division_name: "",
            dept_name: "",
            designation_name: "",
            emp_name: "",
            user_name: "",
            total_pending:"12",
            today_pending:"1",
          },
          task_detail:{}
        } 
      ],
      formNewInput:{
        date:""
      },
      display:"",
      date:"",
    }
  }
 
  formNewInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
  }

  onSearchDate=()=>{
    let data={
      "userID":this.props.LOGIN_CHECK.user_ID,
      "date":this.state.formNewInput.date
    }
    //alert(this.state.formNewInput.date)
    this.props.reportAssignTaskView("viewReportNew",data);
  }
  
  onPrint=()=>{
    this.setState({display:"none"},()=>{console.log("okkkkk")})
    //let doc = new jsPDF()
    //doc.fromHTML(<MainDoc task={this.state.task}/>, 10, 10, {'width': 180})
   // doc.save("two-by-four.pdf")
    window.print();
 
  }

  render() {
    const task=this.state.task
    let mno=0
    m=0
    
  // style={{size:'84.1cm 59.4cm',margin:'1cm 1cm 1cm 1cm'}}
    return (
      <>
        
         <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <button onClick={handlePrint}>Print this out!</button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
     




        <div style={{margin:0,padding:0}}>
        <MainDoc ref={(el) => (this.componentRef = el)} task={task} date={this.state.date} />
      </div> 
      </>
   
    
    );
  }
  
  componentWillMount(){
  }
  
  componentDidMount() {
     if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.user_ID!=0){
         let data={
            "userID":this.props.LOGIN_CHECK.user_ID,
            "date":"" 
         }
        //this.props.reportAssignTaskView("viewReportNew",data);
        console.log(JSON.stringify(this.props.location.state))
        this.setState({task:this.props.location.state.task,date:this.props.location.state.date},()=>{console.log("okkkkk")})
    }else{
        this.props.history.push({ pathname: '/'   })
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextprops")
    console.log(nextProps)
    m=0
    if (nextProps.LOGIN_CHECK.login==false && nextProps.LOGIN_CHECK.user_ID==0){
      nextProps.history.push({ pathname: '/'   })
    }else{
      if (nextProps.TYPE=="REPORT_ASSIGN_TASK_VIEW"){
       this.setState({task:nextProps.REPORT_ASSIGN_TASK_VIEW},()=>{console.log("okkkkk")})
      }
    }
  }
}


const MainDoc=(props)=>{
  let task=props.task
  //alert(props.date)
  return (
    <div id="mainContent" name="mainContent" className="mainContent" style={{fontSize:14, margin:0}}>
       <ReportHeaderTop total={task[0].total} today={task[0].today} date={props.date} />
        <hr style={{padding:0, margin:0}}/>
        {
          task.map((row,i)=>(
            <div>
              <ReportHeader data={row.table_header}/>
                  <hr style={{padding:0, margin:0}}/>
                <div className="row" style={{marginBottom:0 }} >
                  <div className="col-2">
                  <Label style={{fontWeight:"bold"}}></Label>
                  </div>
                  <div className="col-2">
                    <Label style={{fontWeight:"bold" }}>Subject</Label>
                  </div>
                  <div className="col-2">
                    <Label style={{fontWeight:"bold"}}>Additional Info</Label>
                  </div>
                  <div className="col-1">
                    <Label style={{fontWeight:"bold"}}>Question To Be Asked</Label>
                  </div>
                  <div className="col-3">
                    <Label style={{fontWeight:"bold"}}>Comment Received</Label>
                  </div>
                  <div className="col-2">
                    <Label style={{fontWeight:"bold"}}>Authorized Comment</Label>
                  </div>
                  
                </div>
                

              <ReportBody data={row.table_data} />
             
             
            </div>
          ))
        }
    </div>
  )
}

const AuthorizedComment=(props)=>{
  const {authorized_comment}=props.data
  return(
    <Row style={{ marginBottom:0}}>
      <Col xl={12} lg={12} md={12} >
        {authorized_comment}
      </Col>
    </Row>
  )
}

const CommentReceived=(props)=>{
  const {comment_received}=props.data
  //console.log("basic detail")
  //console.log(props.data)
  return(
    <Row style={{ marginBottom:0}}>
      <Col xl={12} lg={12} md={12}  style={{fontWeight:"bold", color:"#00AA00" }}>
       {comment_received}
      </Col>
    </Row>
  )
}

const QuestionAsked=(props)=>{
  let {question,reply_date}=props.data
  // let msg= JSON.stringify(question)
  //msg=msg.replace(/['"]+/g, '')
  //alert(JSON.stringify(question))
  return(
    <Row style={{ marginBottom:0}}>
      <Col xl={12} lg={12} md={12}  >
         {question.question_asked}<br/><br/>
         {/*<strong>Target Date</strong>:   <Label for="" style={{fontWeight:"bold", color:"blue" }}>{Moment(reply_date).format('DD-MM-YYYY')}</Label> */}
      </Col>
    </Row>
  )
}

const AdditionalInfo=(props)=>{
  const {followup_count,day_count,priority_type,followup_type,second_person}=props.data
    let {question,reply_date}=props.target_date
  //console.log("basic detail")
  //console.log(props.data)
  return(
    <Row style={{ marginBottom:0}}>
      <Col xl={12} lg={12} md={12} >
        <strong>Total No of Followup</strong>: {followup_count}  <br/>
        <strong>No. of Days</strong>: {day_count}  <br/>
        <strong>Priority</strong>: {priority_type}  <br/>
        <strong>Target Date</strong>:  <Label for="" style={{fontWeight:"bold", color:"blue" }}>{Moment(reply_date).format('DD-MM-YYYY')}</Label>   <br/>
       {/* <strong>Second Line Person</strong>: {second_person}  <br/>
        <strong>Followup Type</strong>: {followup_type}  <br/>*/}
      </Col>
    </Row>
  )
}

const Subject=(props)=>{
  const {subject,message}=props.data
  //console.log("basic detail")
  //console.log(props.data)
  return(
    <Row style={{ marginBottom:0}}>
      <Col xl={12} lg={12} md={12} style={{fontWeight:"bold", color:"red" }} >
        {subject}
        <br/> <br/>
        {/*  <strong>Message</strong>:   <br/>
        {message}*/}
      </Col>
    </Row>
  )
}

const BasicDetail=(props)=>{
  const {ID,issue_date,company_name}=props.data
  let i=props.sno+1
  m=m+1
  //console.log("basic detail")
  //console.log(props.data)
  //alert(JSON.stringify(props))
  //{company_name}
  return(
   
    <Row style={{ marginBottom:0}}>
       
      <Col xl={12} lg={12} md={12} >
       <strong>M Sn</strong>: {m}  <br/>
        <strong>S No</strong>: {i}  <br/>
        <strong>Issue No</strong>: {ID}  <br/>
        <strong>Issue Date</strong>: {issue_date}  <br/>
        <strong>Company Name</strong>:Kanker Roadways
  <br/>
      </Col>
    </Row>
  )
}

const ReportBody= (props) => {
  const data=props.data!==undefined?props.data:undefined
  console.log("Props")
  //alert(JSON.stringify(props))
  if (props.data !== undefined){
  let i=0
    return(
      <div>
        {
          data.map((row,i)=>(
          <>
            <div className="row" style={{marginBottom:0, padding:0}}>
              <div className="col-2">
                <BasicDetail data={row.basic_detail} sno={i}  />
              </div>
              <div className="col-2">
                <Subject data={row.subject} />
              </div>
              <div className="col-2">
                <AdditionalInfo data={row.additional_info} target_date={row.question_asked} />
              </div>
              <div className="col-1">
                <QuestionAsked data={row.question_asked} />
              </div>
              <div className="col-3">
                <CommentReceived data={row.comment_received} />
              </div>
              <div className="col-2">
                <AuthorizedComment data={row.authorized_comment} />
              </div>
             
            </div>
             <hr style={{padding:0, margin:0}}/>
           
          </>
          
          ))
        }

        
      </div>
    )
  }else{
      return(
        <div></div>
      )
    }
}

const ReportHeaderTop = (props) => {
    //<div className="row" style={{position:"fixed",width:"100%", marginBottom:50}}>
    //alert(JSON.stringify(props))
  return(
    
      <div className="row" >
        <div className="col-3" style={{fontSize:18}}>
          <strong>Daily Followup for Pending Task</strong>
        </div>
        <div className="col-6" style={{textAlign:"center", margin:0,padding:0}}>
         <strong style={{margin:0,padding:0}}>
         <label style={{fontSize:22,textAlign:"center" , margin:0,padding:0}}>Kanker Roadways Raipur
</label><br/>
         <label style={{fontSize:14, textAlign:"center", margin:0,padding:0, }}>Followup Date: {props.date}</label>
         </strong>
        </div>

        <div className="col-3">
          <strong>Total Followup Points</strong> : {props.total}  <br/>
          <strong>Today's Followup Points</strong> : {props.today} <br/>
        </div>
      </div>
   )
}

const ReportHeader = (props) => {
  if (props.data !== undefined){
    console.log(props.data)
  const {division_name,dept_name,designation_name,emp_name,total_pending,today_pending}=props.data
  return(
      <div className="row" style={{marginBottom:0 }}>
        <div className="col-4">
          <strong>Division</strong> : {division_name} <br/>
          <strong>Department</strong> : {dept_name}  <br/>

        </div>
        <div className="col-4">
          Person Name :<strong> {emp_name} </strong> <br/>
          <strong>Desigination</strong> : {designation_name}  <br/>
        </div>

        <div className="col-4">
          <strong>Total Pending</strong> : {total_pending}  <br/>
          <strong>Today's Pending</strong> : {today_pending} <br/>
        </div>
      </div>
   )
  }else{
     return(
        <div></div>
      )

  }

}

function mapStateToProps({ mainReducers}) {
  console.log("First")
  console.log(mainReducers)
   return {
    TYPE:mainReducers.TYPE,
     LOGIN_CHECK:mainReducers.LOGIN_CHECK,
     REPORT_TASK:mainReducers.REPORT_TASK,
     REPORT_ASSIGN_TASK_VIEW:mainReducers.REPORT_ASSIGN_TASK_VIEW,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestPage) ;
