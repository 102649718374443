import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'
class PrivacyPolicy extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
       mobile_no:"",
       username:""
      },
      loading:false,
    }
  } 

  handleSubmit = event => {
    if (this.state.formNewInput.mobile_no===""){
      alert("Mobile No is Empty")
    }
    else if (this.state.formNewInput.username==""){
      alert("Username  is Empty")
    }
    else{
      this.props.userRequestOTP("userRequestOTP",this.state.formNewInput);
     // this.setState({loading:true},()=>{console.log("okkkkk")})

    }
  };

  formNewInputChange=(event)=> {

    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    console.log(this.state.formNewInput)
    console.log("value"+value)
  }
  onSubmit = () => {
   // alert("ok1")
    this.props.history.push('/');
  };
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div class="row" style={{margin:10,padding:20, color:'white' }}>
        <div className="col-12">
        <h1>KANKERROADWAYS.COM Privacy Policy</h1>
        <p>When you use our services, you're trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control.
By accessing or using the Services, you agree to this Privacy Policy, and our Terms of Service. If you have any questions about this Privacy Policy, you can contact us </p>

<h5>Information KANKERROADWAYS.COM Collects</h5>
<p>
  <h5>Your activity & apps</h5>
  At the time you register for KANKERROADWAYS.COM, you voluntarily give us information such as your name and email address. You have the access and can update this information under your personal Account Settings at any time. We also collect aggregated, anonymous user data about the use of the app. 
</p>
<p>
  <h5>Your browsers & devices</h5>
  The user data we collect is used to enhance KANKERROADWAYS.COM and the quality of our service. We only collect personal data required to deliver our services, and we only store it to the extent that it is essential to perform these services.
</p>
<p>
<h5>Your Location Information</h5>
</p>
<p>
<h5>We do not collect information about your location. </h5>
</p>
<p>
<h5>Why KANKERROADWAYS.COM Collects Data</h5>
We use data to build better services. Your information will not be shared with others and will only be used internally for the purposes described below:
</p>

<p>
<h5>Provide our services</h5>
We use your information to deliver our services or information you requested, and to process and complete any transactions.
</p>
<p>
<h5>Maintain & improve our services</h5>
We also use your information to ensure our services are working as intended, such as tracking outages or troubleshooting issues that you report to us. And we use your information to make improvements to our services - for example, to analyze usage and trends with anonymous user data, and to improve the quality of our service and user experience.
</p>
<p>
<h5>Communicate with you</h5>
KANKERROADWAYS.COM collects email addresses from those who communicate with us via email, as well as information provided through voluntary activities such as site registration or survey participation. We use the information we collect to interact with you directly. 
</p>
<p>
<h5>Protect KANKERROADWAYS.COM, our users, and the public</h5>
We use information to help improve the safety and reliability of our services. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm KANKERROADWAYS.COM, our users, or the public.<br/>
We use different technologies to process your information for these purposes. We'll ask for your consent before using your information for a purpose that isn't covered in this Privacy Policy. <br/>
You're also welcome to contact us to ask for our confirmation whenever you feel concerned about how your personal information is being processed, where and for what purpose.
</p>

<p>
<h5>Your Privacy Controls</h5>

While KANKERROADWAYS.COM owns the KRPL application's code, databases, and all rights, you retain all rights to your data. You have choices regarding the information we collect and how it's used. KANKERROADWAYS.COM will never sell your personal data with a third party, and we will never share data to third parties without your permission. When absolutely necessary, KANKERROADWAYS.COM uses third party services and hosting partners who are GDPR-compliant. In such instances, we take essential security methods to guarantee that we comply with GDPR when sending and receiving data from third parties.
</p>
<p>
<h5>Data Retention & Secure</h5>
KANKERROADWAYS.COM is built with strong security features that continuously protect your information. SSL protocol secures all data and information transmitted with Service. The insights we gain from maintaining our services help us detect and automatically block security threats from ever reaching you. And if we do detect something risky that we think you should know about, we'll notify you and help guide you through steps to stay better protected.<br/>
We work hard to protect you and KANKERROADWAYS.COM from unauthorized access, alteration, disclosure, or destruction of information we hold. 
</p>
<p>
<h5>Changes to This Policy</h5>
If our Privacy Policy changes at some time in the future, we will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the latest changes were published. If changes are significant, we'll provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).
Please check back periodically to keep informed of updates or changes to this Privacy Policy. By continuing to access and to use KANKERROADWAYS.COM, you are agreeing to be bound by the revised policy.
</p>
<p>
<h5></h5>
</p>
        </div>
     
      </div>
    );
  }
  componentDidMount() {
    console.log("did mount")
    let data={
        'product_ID':1
    }
   // this.props.productList("productList",data)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.TYPE==='USER_REGISTRATION'){
     
    }
    else if (nextProps.TYPE==='ADD_TO_CART'){
      nextProps.history.push({ pathname: '/' })
    }
    else if (nextProps.TYPE=="USER_REQUEST_OTP"){
      if (nextProps.USER_REQUEST_OTP.STATUS=="SUCCESS"){
        alert(nextProps.USER_REQUEST_OTP.MSG)
        this.props.history.push({pathname: '/user-otp-request',state: { formNewInput:this.state.formNewInput,otp:nextProps.USER_REQUEST_OTP } })
      }
      else{
        alert(nextProps.USER_REQUEST_OTP.MSG)
      }

    }

    this.setState({loading:false},()=>{console.log("okkkkk")})
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

PrivacyPolicy.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

PrivacyPolicy.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  console.log("main reducers")
  console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     USER_REQUEST_OTP:mainReducers.USER_REQUEST_OTP
     
     
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy) ;

